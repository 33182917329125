import { useState, useEffect,useMemo } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import Typography from '@mui/material/Typography';

const AddUser =()=>{

    return
    <>
    <Typography>Add User Page</Typography>
    </>
}

export default AddUser;