// project import
import pages from './pages';
import dashboard from './dashboard';
// import utilities from './utilities';



const menuItems = {
    
    items: [dashboard, pages]
};

export default menuItems;
