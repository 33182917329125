import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { element } from 'prop-types';
import AddUser from 'pages/extra-pages/AddUser';
import Login from 'pages/authentication/Login';
import ProtectedRoute from './ProtectedRoute';



// render - sample page
const ClaimPage = Loadable(lazy(() => import('pages/extra-pages/ClaimPage')));
const Patient = Loadable(lazy(() => import('pages/extra-pages/Patient')));
const Demographics = Loadable(lazy(() => import('pages/extra-pages/Demographics')));
const Claim = Loadable(lazy(() => import('pages/extra-pages/Claim')));
const Insurance = Loadable(lazy(() => import('pages/extra-pages/Insurance')));
const InsurancePage = Loadable(lazy(()=> import('pages/extra-pages/InsurancePage')));
const Practice = Loadable(lazy(()=>import('pages/extra-pages/Practice')));
const User = Loadable(lazy(()=>import('pages/extra-pages/User')));
const Dashboard = Loadable(lazy(()=>import('pages/extra-pages/Dashboard')));
// render - utilities
// const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
// const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
// const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <ProtectedRoute element={MainLayout} />,
    children: [
        {
            path: '/Dashboard',
            element: <ProtectedRoute element={Dashboard}/>
        },
        {
            path: '/Patient',
            element: <ProtectedRoute element={Patient} />
        },
        // {
        //     path: 'dashboard',
        //     children: [
        //         {
        //             path: 'default',
        //             element: <DashboardDefault />
        //         }
        //     ]
        // },
        {
            path: 'ClaimPage',
            element: <ProtectedRoute element={ClaimPage} />
        },
        {
            path: 'Patient',
            element: <ProtectedRoute element={Patient} />
        },
        {
            path: 'Claim',
            element: <ProtectedRoute element={Claim} />
        },
        {
            path: 'Demographics',
            element: <ProtectedRoute element={Demographics} />
        },
        {
            path: 'Insurance',
            element: <ProtectedRoute element={Insurance}/>
        },
        {
            path: 'InsurancePage',
            element: <ProtectedRoute element={InsurancePage}/>
        },
        {
            path: 'Practice',
            element: <ProtectedRoute element={Practice}/>
        },
        {
            path: 'User',
            element: <ProtectedRoute element={User}/>
        },
        {
            path: 'AddUser',
            element: <ProtectedRoute element={AddUser}/>
        }

        
    ]
};

export default MainRoutes;
