import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import NavGroup from './NavGroup';
import { ChromeOutlined, FileWordOutlined, QuestionOutlined, UserOutlined, SettingOutlined,UserAddOutlined,DashboardOutlined } from '@ant-design/icons';

const Navigation = () => {
    const userType = localStorage.getItem('userType');
    const icons = {
        ChromeOutlined,
        QuestionOutlined,
        UserOutlined,
        FileWordOutlined,
        UserAddOutlined,
        DashboardOutlined,
        SettingOutlined
    };
    
    const [pages, setPages] = useState({
        id: 'support',
        title: '',
        type: 'group',
        children: []
    });

    useEffect(() => {
        const support = {
            id: 'support',
            title: '',
            type: 'group',
            children: [
                // {
                //     id: 'claim',
                //     title: 'Claim',
                //     type: 'item',
                //     url: '/Claim',
                //     icon: icons.FileWordOutlined
                // }
            ]
        };
        
        if (userType !== 'Billing Rep') {
            support.children.push(
                {
                    id: 'Dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/Dashboard',
                    icon: icons.DashboardOutlined
                },
                {
                    id: 'Patient',
                    title: 'Patient',
                    type: 'item',
                    url: '/Patient',
                    icon: icons.UserAddOutlined
                },
                {
                    id: 'User',
                    title: 'User',
                    type: 'item',
                    url: '/User',
                    icon: icons.UserOutlined
                }
            );
        }

        setPages(support);
    }, [userType]);

    // Check if pages has children before rendering NavGroup
    return (
        <Box sx={{ pt: 2 }}>
            {pages.children.length > 0 ? (
                <NavGroup key={pages.id} item={pages} />
            ) : (
                <Typography variant="h6" color="error" align="center">
                    No Navigation Items
                </Typography>
            )}
        </Box>
    );
};

export default Navigation;
